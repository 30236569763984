import { useState, useEffect, useMemo } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import axios from "axios";
import InputMask from 'react-input-mask';

import { Link, Navigate, useNavigate } from "react-router-dom";

import { getDoc, getDocs, doc, collection, DocumentReference, querySnapshot, onSnapshot, updateDoc, limit } from "firebase/firestore";
import { auth, db } from "../firebase-config";
import uuid from 'react-uuid';

import { IoChevronBack, IoPersonCircleOutline, IoWallet } from 'react-icons/io5';

import { HiHome } from 'react-icons/hi';
import { FaRegStar } from "react-icons/fa";
import { FaRegComments } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { FaWallet } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaCrown } from "react-icons/fa";
import { IoMdExit } from "react-icons/io";

import { RiSmartphoneFill, RiHeartLine, RiHeartFill } from 'react-icons/ri';
import { AiOutlineLoading3Quarters, AiTwotoneDislike, AiTwotoneLike } from 'react-icons/ai';
import { MdEmail, MdPhotoLibrary, MdNotificationsActive } from 'react-icons/md';
import { FaRandom, FaAddressCard } from 'react-icons/fa';
import { TbMessageCircle2 } from 'react-icons/tb';
import { IoMenu } from 'react-icons/io5';
import { BiLogOut } from 'react-icons/bi';
import { FaHandHoldingUsd } from 'react-icons/fa';

import { BiMenuAltRight } from "react-icons/bi";
import { BsCartCheck } from "react-icons/bs";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";


import Modal from 'react-modal';
import data from '../data/posts';

import toast, { Toaster } from 'react-hot-toast';
import posts from "../data/posts";

const notCompleted = () => toast('Saldo insuficiente para saque, complete a meta de R$ 2.000,00!');
const insufficient = () => toast('Você não pode solicitar saque de um valor maior do que possui na carteira!');
const missingData = () => toast('Dados insuficientes, insira sua chave pix para continuar!');
const dailyLimit = () => toast('Você alcançou o limite diário de R$30');
const termsAccepted = () => toast('Você aceitou os termos ✅!');

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

var logo = require('../assets/logo.png');
var meca1 = require('../assets/meca.jpg');

function Cadastro() {
    const navigate = useNavigate();
    const [cpf, setCpf] = useState(false);
    const [inputName, setInputName] = useState(30);
    const [tel, setTel] = useState('');
    const [money, setMoney] = useState(localStorage.getItem('money'));

    const customStyles = {
        content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px 15px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column'
        },
    };  


    const generatePix =  async () =>  {
        const formattedCpf = cpf.replace(/[.-]/g, '');

         const requestData = {
          requestNumber: "12345",
          dueDate: "2022-10-30",
          amount:  parseFloat(localStorage.getItem('money').replace(',', '.')),
          client: {
              name: inputName,
              document: formattedCpf,
              email: 'teste@gmail.com',
          }
      };
  
      const axiosConfig = {
          headers: {
            'ci': 'omarcocost_1704896260264',
            'cs': '1c9f8a0ab0671b218337b9447792fa0834d6af3e480c277ff30aa908440b52ad499f8482a4d640e5b784b9f14ea405b6',
            'Content-Type': 'application/json',
          }
      };
  
      try {
          const response = await axios.post('https://ws.suitpay.app/api/v1/gateway/request-qrcode', requestData, axiosConfig);
          console.log(requestData, response.data);
          localStorage.setItem('nome', inputName);
          localStorage.setItem('telefone', tel);
          localStorage.setItem('codigo', response.data.paymentCode);
          localStorage.setItem('base64', response.data.paymentCodeBase64)
          navigate('/pix');
      } catch (error) {
          console.error('Erro na requisição:', error.response ? error.response.data : error.message, requestData);
          if (error.response.data) {
              if (error.response.data.message == "Invalid user identification number") {
                  alert('CPF INVÁLIDO')
              }
          }
      }
  
    }

    return (
        <>
            <div className="cadastro">
               <div className="wrapperInputs">
                    <h2>Cadastro</h2>
                    <input type="text" placeholder="Digite seu nome..." onChange={(event) => {
                      setInputName(event.target.value);
                }} />
                                    <input type="tel" placeholder="Digite seu telefone..." onChange={(event) => {
                      setTel(event.target.value);
                }} />

                 <InputMask
      mask="999.999.999-99"
      value={cpf}
      onChange={(event) => setCpf(event.target.value)}
    >
      {(inputProps) => <input {...inputProps} type="tel" placeholder="Digite seu CPF..." />}
    </InputMask>
                <button className="buttonBlue" onClick={generatePix}>Gerar Pix</button>
               </div>
            </div>
        </>
    );
}

export default Cadastro;