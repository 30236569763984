import { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { addDoc, collection, doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db, provider } from "../firebase-config";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Link,
	useNavigate
  } from "react-router-dom";


import { MdOutlineLogout } from 'react-icons/md';
import { IoChevronBack, IoPersonCircleOutline } from 'react-icons/io5';
import { FaRegUser } from "react-icons/fa";
var logo = require('../assets/logo.png');
var banner = require('../assets/banner.jpeg');

function Refund(props) {
  const navigate = useNavigate();

  return (
        <div className="main">
            <section className="sectionRefund">
            <h1 className="text">Refund Request Sent Successfully! 📧</h1>
            <p className="paragrafo">
                Your refund request has been received. You will receive an email with
                the status of your request shortly.
            </p>
            <p className="paragrafo">
                Please check your spam/junk folder as the email might be there by
                mistake.
            </p>
            </section>
        </div>     
  );
}

export default Refund;