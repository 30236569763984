import { useState, useEffect, useMemo } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import axios from "axios";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link, Navigate, useNavigate } from "react-router-dom";

import { getDoc, getDocs, doc, collection, DocumentReference, querySnapshot, onSnapshot, updateDoc, limit } from "firebase/firestore";
import { auth, db } from "../firebase-config";
import uuid from 'react-uuid';

import { IoChevronBack, IoPersonCircleOutline, IoWallet } from 'react-icons/io5';

import { FaAngry } from "react-icons/fa";
import { IoIosHappy } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";

import { BiMenuAltRight } from "react-icons/bi";
import { BsCartCheck } from "react-icons/bs";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";


import Modal from 'react-modal';
import data from '../data/posts';

import toast, { Toaster } from 'react-hot-toast';
import posts from "../data/posts";

const notCompleted = () => toast('Saldo insuficiente para saque, complete a meta de R$ 2.000,00!');
const insufficient = () => toast('Você não pode solicitar saque de um valor maior do que possui na carteira!');
const missingData = () => toast('Dados insuficientes, insira sua chave pix para continuar!');
const dailyLimit = () => toast.error('Daily limit reached');

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

var logo = require('../assets/logo.png');
var account = require('../assets/account.jpeg');
var accountSvg = require('../assets/home.svg');
var home = require('../assets/account.svg');

var check = require('../assets/check_circle_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.png');

function Home() {
    const navigate = useNavigate();
    const [disabledButtons, setDisabledButtons] = useState(Array(6).fill({ disabled: false, activeIndex: null }));
    const [displayedPosts, setDisplayedPosts] = useState(shuffleArray(posts).slice(0, 6));
    const [money, setMoney] = useState(0);
    const [purchaseDate, setPurchaseDate] = useState('');
    const [amount, setAmount] = useState(0);
    const [likeCount, setLikeCount] = useState(0);
    const [moneyToday, setMoneyToday] = useState(0);
    const [moneyFormated, setMoneyFormated] = useState('0');
    const [userEmail, setUserEmail] = useState('');
    const [user, setUser] = useState({});
    const [post, setPosts] = useState([]);
    const [refreshing, setRefreshing] = useState(false);
    const [modalLimit, setModalLimit] = useState(false);
    const [modalPlan, setModalPlan] = useState(false);
    const [limit, setLimit] = useState(15);
    const [limited, setLimited] = useState(false);
    const [modalSuperPost, setModalSuperPost] = useState(false);
    const [alreadyLiked, setAlreadyLiked] = useState(false);
    const [homeScreen, setHomeScreen] = useState(true);
    const [saque, setSaque] = useState(false);
    const [progress, setProgress] = useState(10);
    const [nome, setNome] = useState(localStorage.getItem('nome'));
    const [fullname, setFullname] = useState("");
    const [order, setOrder] = useState("");
    const [version, setVersion] = useState(0);


    const postsData = data;
    let date = '';
    var logo = require('../assets/logo.png');

    const customStyles = {
        content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px 15px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column'
        },
    };  

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
      };

    function closeModal() {
        setModalPlan(false);
    }

    const updateProgress = (money) => {
        const minAmount = 300;
        const maxAmount = 1000;
        const progress = ((money - minAmount) / (maxAmount - minAmount)) * 100;
        return Math.min(100, Math.max(10, progress)); // Garante que o progresso esteja entre 0 e 100
    };


    useEffect(() => {
        document.title = 'InstaCash - Home';

        auth.onAuthStateChanged(function(authUser) {
            if (authUser) {
            setUser({user});
            setRefreshing(true)
            const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];

            const likesCount = parseInt(localStorage.getItem(currentDate)) || 0;
            setPosts(getMultipleRandom(1));
            } else {
            navigate('/login')
            }
        });
    }, []);

    useEffect(() => {
        const getMoney = async () => {
            const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];
            const likesCount = parseInt(localStorage.getItem(currentDate)) || 0;
            
            setLikeCount(likesCount);
    
            const docSnap = await getDoc(doc(db, "users", auth.currentUser.uid));
            const moneyValue = docSnap.data().money;
            setMoney(moneyValue);
            setUserEmail(docSnap.data().email);
            setPurchaseDate(docSnap.data().processed_at);
            setAmount(docSnap.data().pagou);
            if (docSnap.data().version) {
                setVersion(docSnap.data().version)
            }
            if (docSnap.data().name) {
                setFullname(docSnap.data().name)
            }
            if (docSnap.data().order) {
                setOrder(docSnap.data().order)
            }
            setNome(localStorage.getItem('nome'))
            console.log('aaa', localStorage.getItem('nome'))
            console.log('teste', docSnap.data().email, docSnap.data().money)
            
            if (likeCount > limit) {
                setModalLimit(true);
                setAlreadyLiked(true);
            }
    
            let formattedMoney;
            if (!Number.isInteger(moneyValue)) {
                formattedMoney = moneyValue.toString().replace('.', ',');
            } else {
                formattedMoney = moneyValue;
            }
            setMoneyFormated(formattedMoney);
            setProgress(updateProgress(moneyValue));

        }
        getMoney();

    }, [moneyFormated, user]);

    const getMultipleRandom = (num) => {
        const shuffled = [...postsData].sort(() => 0.5 - Math.random());

        return shuffled.slice(0, num);
    }

    const updateMoney = async (val) => {
        const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];
        const likesCount = parseInt(localStorage.getItem(currentDate)) || 0;

        if (likesCount < 20) {
            console.log('contagem', likesCount)
            setRefreshing(true)
            const newClicks = likesCount + 1;
            localStorage.setItem(currentDate, newClicks);
            
            setLikeCount(newClicks);
            localStorage.setItem(`${currentDate}-timestamp`, new Date().getTime());

            const userMoney = doc(db, "users", auth.currentUser.uid);
            const total = parseFloat(money) + parseFloat(val)
            const newMoney = { money: parseFloat(total).toFixed(2)};
            console.log('total', money, total)

            const isNumber = typeof total;
            if (isNumber == 'number') {
              await updateDoc(userMoney, newMoney);
              setMoney(total.toFixed(2));
              setMoneyFormated(total.toString().replace('.', ','))
            }
            
            if (localStorage.getItem(date)) {
                var totalToday = parseFloat(localStorage.getItem(date)) + 1;
            } else {
                var totalToday = 1;
            }
    
            setMoneyToday(totalToday);
            localStorage.setItem(date, totalToday);
    
            if (!Number.isInteger(total)) {
              let formated = total.toString().replace('.', ',');
              setMoneyFormated(formated);
            } else {
              setMoneyFormated(total);
            }
            if (post.length == 1) {
                setLimited(true);

            }
        } else {
            dailyLimit();
            setAlreadyLiked(true);
        }

      }

    const logout = async () => {
        await signOut(auth);
    };

    function handlePostClick(postIndex, buttonIndex) {
        const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];
        const likesCount = parseInt(localStorage.getItem(currentDate)) || 0;

        if (likesCount < 20) {
            const updatedDisabledButtons = [...disabledButtons];
            updatedDisabledButtons[postIndex] = {
                disabled: true,
                activeIndex: buttonIndex
            };
            setDisabledButtons(updatedDisabledButtons);
    
            const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }).split(",")[0];
            const likesCount = parseInt(localStorage.getItem(currentDate)) || 0;
    
            const possibleValues = [
                0.25, 0.30, 0.35, 0.42, 0.47, 0.50, 0.55, 0.60, 
                0.65, 0.70, 0.71, 0.83, 0.85, 0.90, 0.93
            ];
        
            const randomValue = possibleValues[Math.floor(Math.random() * possibleValues.length)];
            const updated = () => toast.success(`Thanks for reviewing! Your commission: $${randomValue}`);
            updated()
            updateMoney(randomValue);
        } else {
            dailyLimit()
        }

      }

      function shuffleArray(array) {
        return array.sort(() => Math.random() - 0.5);
    }

    const handleLoadMore = () => {
        const newShuffledPosts = shuffleArray(posts).slice(0, 6);
        setDisplayedPosts(newShuffledPosts);
        setDisabledButtons(Array(6).fill({ disabled: false, activeIndex: null }))
    };

    const refund = () => {

        const data = {
            "data" : {
                "email": userEmail,
                "name": fullname,
                "order": order,
                "purchaseDate": purchaseDate,
                "amount": amount
            }

        }

        console.log(data, version)
        if (version) {
            axios
            .post('https://cupom-server-3857a533d564.herokuapp.com/api/insta-cash/send-email-2', data)
            .then((response) => {
              console.log('response 2', response)
              navigate('/refund')
            });
        } else {
            axios
            .post('https://cupom-server-3857a533d564.herokuapp.com/api/insta-cash/send-email', data)
            .then((response) => {
              console.log(response)
              navigate('/refund')
            });
        }

    }



      const listPosts = displayedPosts.map((val, index) =>
      <>
          <div key={index} id="posts" className="container-perfis">
            <div className="col-lg-6 offset-lg-3 boxl1">
                <div id="looks" className="look">
                    <div className="perfil">
                        <div className="postheader">
                            <div style={{width: '100%'}} className="perfil-info d-flex justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="foto-perfil">
                                        <img src={require(`../assets/${val.profilePicture}`)} alt="uang" />
                                    </div>
                                    <h1 className="nome-perfil">{val.name}</h1>
                                </div>
                                <BsThreeDots size={20} />
                            </div>

                        </div>
                    </div>
                    <div className="feed">
                        <div className="postagem">
                            <img src={require(`../assets/${val.feedPicture}`)}  alt="#Paris, such a beautiful city…." />
                        </div>
                    </div>
                    <div style={{paddingLeft: '10px', paddingBottom: 12, paddingRight: 10}}>
                        <span className="nome2">{val.name}</span>
                        <span id="legenda">{val.desc}</span>
                    </div>
                </div>
            </div>
            <center>
          <div className="maispadding" id="overlay" style={{display: 'block'}}>
            <div className="row g-0 lookrate" id="look1" style={{gap: '10px'}}>
              <div id="btn1-d" onClick={() => {handlePostClick(index, 0)}}                             className={`col btn-voto n-1 d-flex align-items-center justify-content-center ${disabledButtons[index].disabled ? 'disabled' : ''} ${disabledButtons[index].activeIndex === 0 ? 'active' : ''}`}
>
                <FaAngry />
              </div>
              <div id="btn1-l" onClick={() => {handlePostClick(index, 1)}}                             className={`col btn-voto n-5 d-flex align-items-center justify-content-center ${disabledButtons[index].disabled ? 'disabled' : ''} ${disabledButtons[index].activeIndex === 1 ? 'active' : ''}`}
>
                <IoIosHappy />
              </div>
            </div>
          </div>
          
        </center>
            </div>
      </>
      );

    return (
        <>
            <div className="headerHome">
                <img src={logo} style={{width: '100px', height: 'auto', paddingTop: 10}} />
                <center><a onClick={() => {setHomeScreen(false)}} className="btn-refund2"><b>Refund</b></a></center>
                    <div className="account-info">
                        <img src={account} />
                        <p id="username2" style={{fontWeight: 'bold'}}>{nome}</p>
                    </div>
                </div>
                <div className="balance">
                    <div className="justify-between flex">
                        <div className="balance+saldo">
                        <h4>Balance</h4> <div id="saldoValue" className="saldo"><b>${money}</b></div>
                    </div>
                    <div className="box-icon">
                        <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" width="20"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"></path></svg>
                        </div>
                    </div>
                </div>
                <div className="progress-container" style={{display: 'flex'}}>
                    <div className="progress-bar" style={{width: `${progress}%`}} ></div>
                </div>
                <p className="value">Min: $1.000</p>
            </div>
            {
                homeScreen ?
                <>
                    <div className="page">
                        {listPosts}
                        <center>
                          <div class="maispadding flex-cloumn">
                            <p>Click the button below to load more posts</p>
                            <a href="#" className="refresh n-5 d-flex flex-cloumn align-items-center justify-content-center" onClick={() => { handleLoadMore() }}>Load more</a>
                             </div>
                           </center>
                    </div>
                </>
                :
                <>
                <div className="col-lg-6 offset-lg-3 boxl2"  style={{paddingTop: '150px'}} >
                    <div id="aba-account" className="account-box look accountbox"  style={{padding: '10px 10px'}}>
                    <div className="account-look">
                            <h2 id="amount" style={{margin: 0, fontWeight: 600, paddingBottom: 8, fontSize: '100%'}}>
                            Account information<span className=""></span></h2>
                            
                            <div className="inputy">
                            <p id="username">{nome}</p>
                            </div>

                            <div className="inputy">
                                <p id="email">{userEmail}</p>
                                </div>
                                <button id="withdrawButton" className="withdraw" onClick={() => {setSaque(true)}}>withdraw balance</button>
                                {
                                    saque &&
                                    <p id="avisosacar" style={{color: 'red', paddingTop: 13, margin: 0, fontSize: 15}}>Your balance is below the minimum withdrawal amount ($3,000.00). Please continue rating posts to increase your balance.</p>
                                }
                        </div> 
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-3  boxl2">
                    <div id="aba-transaction" className="accountbox look" style={{padding: '10px 20px'}}>
                    <p>If you wish to request a refund for any reason, just click below.</p>
                        <center><a className="btn-refund" onClick={() => {refund()}}>Request a refund</a></center>
                        <br />
                        
                            <h2 style={{margin: 0, fontWeight: 600, fontSize: '100%'}}>Withdrawal History</h2>
                            <hr style={{margin: '10px 0'}} />
                            <p style={{margin: 0}}>No recent data</p>

                            
                        </div> 
                    </div>
                </>
            }
            <div className="footer">
                <div className="navbar">
                    <button className="home-btn selected" id="homeButton" onClick={() => {setHomeScreen(true)}}>
                    <svg style={{width: 30}} viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M512 128 128 447.936V896h255.936V640H640v256h255.936V447.936z"/></svg> </button>
                    <button className="account-btn" id="accountButton" onClick={() => {setHomeScreen(false)}}>
                    <svg style={{width: 30}}  fill="#000000" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M16 15.503A5.041 5.041 0 1 0 16 5.42a5.041 5.041 0 0 0 0 10.083zm0 2.215c-6.703 0-11 3.699-11 5.5v3.363h22v-3.363c0-2.178-4.068-5.5-11-5.5z"/></svg></button>
                </div>
            </div>
            <Toaster />
        </>
    );
}

export default Home;