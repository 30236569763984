import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from '@firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDCjrFpO0Lr_JqF8ZEav2gBddterj2nzio",
  authDomain: "insta-cash4.firebaseapp.com",
  projectId: "insta-cash4",
  storageBucket: "insta-cash4.appspot.com",
  messagingSenderId: "148875047226",
  appId: "1:148875047226:web:e6cae422c55bafb9bda003",
  measurementId: "G-S45SWJJKLD"
};

const app = initializeApp(firebaseConfig);


const analytics = getAnalytics(app);

export const auth = getAuth(app);

export const db = getFirestore(app);

export const provider = new GoogleAuthProvider();
