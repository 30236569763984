import { useState, useEffect, useMemo } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";

import axios from "axios";
import InputMask from 'react-input-mask';

import { Link, Navigate, useNavigate } from "react-router-dom";

import { getDoc, getDocs, doc, collection, DocumentReference, querySnapshot, onSnapshot, updateDoc, limit } from "firebase/firestore";
import { auth, db } from "../firebase-config";
import uuid from 'react-uuid';

import { IoChevronBack, IoPersonCircleOutline, IoWallet } from 'react-icons/io5';

import { HiHome } from 'react-icons/hi';
import { FaRegStar } from "react-icons/fa";
import { FaRegComments } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { FaWallet } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaCrown } from "react-icons/fa";
import { IoMdExit } from "react-icons/io";

import { RiSmartphoneFill, RiHeartLine, RiHeartFill } from 'react-icons/ri';
import { AiOutlineLoading3Quarters, AiTwotoneDislike, AiTwotoneLike } from 'react-icons/ai';
import { MdEmail, MdPhotoLibrary, MdNotificationsActive } from 'react-icons/md';
import { FaRandom, FaAddressCard } from 'react-icons/fa';
import { TbMessageCircle2 } from 'react-icons/tb';
import { IoMenu } from 'react-icons/io5';
import { BiLogOut } from 'react-icons/bi';
import { FaHandHoldingUsd } from 'react-icons/fa';

import { BiMenuAltRight } from "react-icons/bi";
import { BsCartCheck } from "react-icons/bs";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";


import Modal from 'react-modal';
import data from '../data/posts';

import toast, { Toaster } from 'react-hot-toast';
import posts from "../data/posts";

var logo = require('../assets/logo.png');
var meca1 = require('../assets/meca.jpg');

function Pix() {
    const [cpf, setCpf] = useState(false);
    const [nome, setNome] = useState(localStorage.getItem('nome'));
    const [telefone, setTel] = useState(localStorage.getItem('telefone'));

    const totalTime = 29 * 60 + 59; // Tempo total em segundos (29 minutos e 59 segundos)
    const [timeLeft, setTimeLeft] = useState(totalTime);
    const data = new Date();
    const formattedDate = data.toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
    
    // formattedDate será "07-08-2024 23:30"
    console.log(formattedDate);
    function copyPix() {
        document.execCommand("copy");
        var copyText = localStorage.getItem('codigo')
        navigator.clipboard.writeText(copyText);
    
        alert("Chave pix 'Copia e Cola' copiada com sucesso!")
    }

    const base64String = localStorage.getItem('base64'); // Substitua com a sua string base64
    const mimeType = "image/jpeg"; // Substitua com o mime type correto
    const base64Image = `data:${mimeType};base64,${base64String}`;

    useEffect(() => {
        const timer = setInterval(() => {
          setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);
    
        return () => clearInterval(timer); // Limpa o intervalo quando o componente desmonta
      }, []);
    
      function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
      };
    
      const progress = ((totalTime - timeLeft) / totalTime) * 100;
    return (
        <>
            <div className="App">
            <header>
                    <BiMenuAltRight size={35} color="white" />
                    <img src={logo} />
                    <BsCartCheck size={30} color="#444444"/>
                </header>
                <div style={{margin: '100px 3%'}}>
                    <div className="titleHeader" >
                    <svg xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 -960 960 960" width="34px" fill="#EDB409"><path d="m424-296 282-282-56-56-226 226-114-114-56 56 170 170Zm56 216q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/></svg>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <h2>Aguardando Pagamento!</h2>
                        <p>Finalize o pagamento</p>
                    </div>
                    </div>
                    <div className="card">
                        <div style={{padding: 10, display: 'flex', flexDirection: 'column'}}>
                        <h3 style={{fontSize: 17, fontWeight: 500, textAlign: 'center', margin: 0}}>
        Você tem <span style={{fontSize: 18, fontWeight: 'bold'}}>{formatTime(timeLeft)}</span> para pagar
      </h3>
      <div
        style={{
          width: '100%',
          height: '20px',
          backgroundColor: '#e0e0e0',
          borderRadius: '10px',
          overflow: 'hidden',
          marginTop: '10px',
            marginBottom: '20px'
        }}
      >
        <div
          style={{
            width: `${progress}%`,
            height: '100%',
            backgroundColor: 'red',
          }}
        ></div>
      </div>
                        <div style={{display: 'flex', alignItems: 'center', margin: '5px 0px', fontSize: 14}}><span className="bg-success">1</span> <p>Copie o código PIX abaixo.</p></div>
                        <div style={{width: '100%', display: 'flex'}}>
                            <input className="copyInput"  readonly value={localStorage.getItem('codigo')} /> 
                            <button onClick={() => {copyPix()}} className="btn-copy">Copiar</button>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', margin: '5px 0px', fontSize: 14}}><span className="bg-success">2</span> <p>Abra o app do seu banco e escolha a opção PIX, como se fosse fazer uma transferência.</p></div>
                        <div style={{display: 'flex', alignItems: 'center', margin: '5px 0px', fontSize: 14}}><span className="bg-success">3</span> <p>Selecione a opção PIX cópia e cola, cole a chave copiada e confirme o pagamento.</p></div>
                        <div className="alert">Este pagamento só pode ser realizado dentro do tempo, após este período, caso o pagamento não for confirmado os números voltam a ficar disponíveis.
                        </div>
                        <hr />
                        <img className="qrcode" src={base64Image} />
                        <h2 style={{textAlign: 'center', margin: 0}}>QR Code</h2>
                        <p style={{textAlign: 'center', fontSize: 12}}>Acesse o APP do seu banco e escolha a opção pagar com QR Code, escaneie o código ao lado e confirme o pagamento.
                        </p>
                        </div>
                    </div>
                    <div className="alert-info">Este pagamento só pode ser realizado dentro do tempo, após este período, caso o pagamento não for confirmado os números voltam a ficar disponíveis.
</div>
<div className="card">
    <div className="cardContainer">
    <p style={{fontSize: 15, opacity: '.8'}}>Detalhes da sua compra</p>
    <p style={{fontSize: 10, opacity: '.5'}}>df1088bd3293eb378d0183a67d3e0ef3</p>
    <div className="info"><p style={{fontSize: 15, fontWeight: '500'}}>Comprador:</p> <span>{nome}</span></div>
    <div className="info"><p style={{fontSize: 15, fontWeight: '500'}}>Telefone:</p> <span>{telefone}</span></div>
    <div className="info"><p style={{fontSize: 15, fontWeight: '500'}}>Data/Horário:</p> <span>{formattedDate}</span></div>
    <div className="info"><p style={{fontSize: 15, fontWeight: '500'}}>Situação:</p> <span>Aguardando Pagamento</span></div>
    <div className="info"><p style={{fontSize: 15, fontWeight: '500'}}>Qtd. Cotas:</p> <span>{localStorage.getItem('cotas')}</span></div>
    <div className="info"><p style={{fontSize: 15, fontWeight: '500'}}>Total:</p> <span>R$ {localStorage.getItem('money')}</span></div>
    <div className="info"><p style={{fontSize: 15, fontWeight: '500'}}>Cotas:</p> <span>As cotas serão geradas após o pagamento</span></div>
    <div className="alert">O pagamento da sua cota ainda está pendente.
</div>
    </div>
</div>
                </div>
            </div>
        </>
    );
}

export default Pix;