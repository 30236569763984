import { useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import { addDoc, collection, doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db, provider } from "../firebase-config";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Link,
	useNavigate
  } from "react-router-dom";


import { MdOutlineLogout } from 'react-icons/md';
import { IoChevronBack, IoPersonCircleOutline } from 'react-icons/io5';
import { FaRegUser } from "react-icons/fa";
var logo = require('../assets/logo.png');
var banner = require('../assets/banner.jpeg');

function Login(props) {
  const navigate = useNavigate();
  const [nome, setNome] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loginPassword, setLoginPassword] = useState("password123");
  const [passwordError, setPasswordError] = useState("");

  const [user, setUser] = useState({});
  useEffect(()=>onAuthStateChanged(auth,(currentUser)=>{setUser(currentUser);}),[ ])

  const login = async () => {
    try {
      console.log(auth, loginEmail, loginPassword)
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword,
      );
      localStorage.setItem('nome', nome)
	  navigate('/');

    } catch (error) {
      console.log(error.message);
      if (error.message.includes('invalid-email') || error.message.includes('invalid-login-credentials')) {
        setEmailError('Incorrect Email.');
      }  else if (error.message.includes('user-not-found')) {
        setEmailError('Incorrect Email.');  
      }
    }
  };

  return (
        <div className="loginWrapper">
          <div className="top">
            <img src={logo} width="100%" style={{maxWidth: '100px', width: '100%'}} />
          </div>
          <div className="maispadding" style={{margin: '0 auto'}}>
            <div className="container">
              <img src={banner} />
              <section className="header">
                <p style={{fontSize: 18, color: '#4a4a4a', fontWeight: 'bold'}} >
                  Welcome to Instacash!
                </p>
              </section>

              <form id="form" className="form">
                <div className="form-content">
                  <input type="text" id="username" maxLength={15} placeholder="Enter your name..."
                  onChange={(event) => {
                    setNome(event.target.value);
                  }} />
                </div>

                <div className="form-content">
                  <input type="email" 
                      id="email"
                      placeholder="Enter your email used for registration..."
                      onChange={(event) => {
                        setLoginEmail(event.target.value);
                      }}
                  />
                  <p style={{color: 'red', fontSize: 15, marginTop: 5}}>{emailError}</p>
                </div>

                <button type="button" onClick={login}>Log in</button>
              </form>
            </div>
          </div>
        </div>     
  );
}

export default Login;