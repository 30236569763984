import { useEffect, useState } from "react";

import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link
} from "react-router-dom";
import { auth } from "./firebase-config";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Cadastro from "./pages/Cadastro";
import Pix from "./pages/Pix";
import Refund from "./pages/Refund";

function App() {
  const [showMobile, setShowMobile] = useState(true);
  const [money, setMoney] = useState(true)

  useEffect(() => {
    if(window.innerWidth > 765) {
      setShowMobile(false)
    }
  }, []);


  return (
    <Router>

      <div className="wrapper">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login/>} />
          <Route path='/cadastro' element={<Cadastro />} />
          <Route path='/pix' element={<Pix />} />
          <Route path='/refund' element={<Refund  />} />

        </Routes>
      </div>
      
  </Router>
);
}

export default App;